/* Modal.css */

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

body,
.modal {
  font-family: "Source Sans 3", sans-serif;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  backdrop-filter: blur(10px); /* blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  /* background-color: #fff; */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 15%;
  transform: translate(-50%, -50%);
  /* background: rgba(0,0,0,0.8); */
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  display: none; /* Initially hidden */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: dropTop 0.3s linear;
}

.modal-content {
  /* background: white; */
  padding: 20px;
  border-radius: 5px;
}

.modal.open {
  display: flex;
  animation: dropTop 0.5s linear;
}

.modal.close {
  animation: dropBottom 0.5s linear;
}

.close:hover {
  color: black;
  text-decoration: none;
}

.close:focus {
  color: black;
  text-decoration: none;
}

.modal input,
.modal button {
  margin-bottom: 10px;
}

.modal button {
  margin-top: 10px;
}
